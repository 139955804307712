import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { ChevronRight } from '../custom-icons';

function Breadcrumbs({ links }) {
  return (
    <div className="text-sm font-regular md:text-base">
      {links.map((item, index) => (
        <React.Fragment key={item.label}>
          {!item.url ? (
            <span>{item.label}</span>
          ) : (
            <Link to={item.url} className="text-accent-2 link-underline-accent">
              {item.label}
            </Link>
          )}
          {index !== links.length - 1 ? (
            <ChevronRight className="inline-block mx-2" alt="arrow icon" />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
}

Breadcrumbs.propTypes = {
  links: PropTypes.array.isRequired,
};

export default Breadcrumbs;
